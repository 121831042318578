import React from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ children, role }) => {
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("access_token");
  const decodedToken = access_token && jwtDecode(access_token);
  const authorizedRole = decodedToken?.role;
  React.useEffect(() => {
    if (!access_token) {
      navigate("/login");
    } else if (role && !authorizedRole === role) {
      navigate("/dashboard");
    }
    sessionStorage.setItem("role", authorizedRole);
  }, [access_token, authorizedRole, navigate]);

  return access_token ? children : null;
};
export default PrivateRoute;
