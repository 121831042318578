import axios from "axios";
import AlertMessage from "./AlertMessage";
const apiHost = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: apiHost });

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const errorMessage =
        error.response.data.message || "Une erreur s'est produite";
      AlertMessage(errorMessage, "error");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
