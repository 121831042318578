import { notification } from "antd";

const AlertMessage = (message, type) => {
  if (type === "warning") {
    notification.warning({
      message: "Warning",
      description: message,
      duration: 5,
      closable: true,
    });
  } else if (type === "error") {
    notification.error({
      message: "Error",
      description: message,
      duration: 5,
      closable: true,
    });
  } else {
    notification.info({
      message: "Info",
      description: message,
      duration: 5,
      closable: true,
    });
  }
};

export default AlertMessage;
