import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <a href="/" className={styles.link}>Générer un rapport</a>
      <a href="/dashboard" className={styles.link}>Consulter les rapports</a>
    </footer>
  );
};

export default Footer;
