// Signup.js

import React from "react";
import { Form, Input, Button, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import axios from "../utils/axios";
import logo from "../../Assets/logo.svg";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleLogin = () => {
    const values = form.getFieldsValue();
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, values)
      .then((res) => {
        sessionStorage.setItem("access_token", res.data.access_token);
        navigate("/dashboard");
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: err.response.data.message || "Une erreur s'est produite",
          duration: 2,
        });
      });
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
        <h2 className={styles.title}>Se connecter</h2>
        <Form form={form} layout="vertical" onFinish={handleLogin}>
          <Form.Item
            name="username"
            label="Email :"
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre email",
              },
              {
                pattern: emailRegex,
                message: "Veuillez entrer une adresse email valide",
              },
            ]}
          >
            <Input
              className={styles.input}
              placeholder="email@email.com"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe :"
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre mot de passe",
              },
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder="Mot de passe"
              size="large"
            />
          </Form.Item>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              style={{ backgroundColor: "#1b4090" }}
              type="primary"
              htmlType="submit"
            >
              Se connecter
            </Button>
          </div>
        </Form>
        {/* <div className={styles.loginLink}>
          Vous n'avez pas de compte <Link to="/signup">S'inscrire</Link>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
