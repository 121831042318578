import React, { useState } from "react";
import Header from "../../components/Layouts/Header/Header";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/upload.svg";
import classes from "./Home.module.css";
import axios from "../../components/utils/axios";
import tick from "../../Assets/tick.svg";
import warning from "../../Assets/warning.svg";
import errorIcon from "../../Assets/close.svg";

import {
  Col,
  Row,
  Divider,
  ConfigProvider,
  Form,
  Input,
  notification,
  Empty,
  Tabs,
  Radio,
  Table,
  Button,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomSearch from "../../components/CustomSearch";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import Footer from "../../components/Layouts/Header/Footer";
import AlertMessage from "../../components/utils/AlertMessage";
const { TabPane } = Tabs;
const Home = () => {
  const {
    preview,
    setPreview,
    ibanLIst,
    setIbanList,
    payementLIst,
    setPayementList,
    frequencePayementList,
    setFrequencePayementList,
    montantInoherentList,
    setMontantIncoherentList,
    bankInfoChangesList,
    setBankInfoChangesList,
    datePrelevementList,
    setDatePrelevementList,
  } = React.useContext(GlobalContext);
  const [form] = Form.useForm();
  const [activeGroup, setActiveGroup] = useState("avertissement");
  const [activeTab, setActiveTab] = useState("paiementDepasse");
  const [activeTabAvertissement, setActiveTabAvertissement] = useState("WT001");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState();
  const [existe, setExiste] = React.useState();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});
  const navigate = useNavigate();
  const handleTabChange = (e) => {
    setActiveGroup(e.target.value);
  };
  const handleActiveTab = (key) => {
    setActiveTab(key);
  };
  const handleActiveTabAvertissement = (key) => {
    setActiveTabAvertissement(key);
  };
  const files = acceptedFiles.map((file) => (
    <li style={{ listStyle: "none", marginTop: "1rem" }} key={file.path}>
      {file.path}
    </li>
  ));
  const getLists = () => {
    if (acceptedFiles.length > 0) {
      let formData = new FormData();
      formData.append(`selectedFileName`, acceptedFiles[0]);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/validate`, formData, {
          headers: {
            "Access-Token": sessionStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          if (res.data.existe === true) {
            AlertMessage("Ce fichier a déjà été analysé", "warning");
            setExiste(true);
            setPreview(false);
          } else if (res.data.error === false) {
            setExiste(false);
            setError(false);
            setMessage(res.data.message);
            setPreview(false);
            // setIbanList(res?.data?.repeated_transactions || {});
            // setPayementList(res?.data?.invalid_formats || {});
            // setFrequencePayementList(res?.data?.frequence_prelevement || {});
            // setMontantIncoherentList(res?.data?.montant_incoherent || {});
            // setBankInfoChangesList(res?.data?.bank_info_changes || {});
            // setDatePrelevementList(res?.data?.date_prelevementt_check || {});
          } else {
            setExiste(false);
            setError(true);
            setMessage("Fichier non valide");
            setPreview(false);
          }
        })
        .catch((e) => {
          setExiste(false);
          setError(true);
          setMessage("Fichier non valide");
          setPreview(false);
        });
    } else {
      notification.error({
        message: "Erreur",
        description: "Veuillez ajouter le fichier à analyser",
      });
    }
  };
  const saveTransactions = () => {
    if (acceptedFiles.length > 0) {
      let formData = new FormData();

      formData.append(`selectedFileName`, acceptedFiles[0]);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/save`, formData, {
          headers: {
            "Access-Token": sessionStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          if (res.data.error === false) {
            setMessage("Sépa validé avec succès");
          } else {
            setMessage("Sépa non valide");
          }
        })
        .catch((e) => {
          setMessage("Sépa non valide");
        });
    } else {
      notification.error({
        message: "Erreur",
        description: "Veuillez ajouter vos documents",
      });
    }
  };
  const generateColumns = (title, dataIndex) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CustomSearch
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });
  const ibanRepetee = Object.entries(ibanLIst).map(([key, value]) => {
    return {
      key: key,
      iban: key,
      count: value,
    };
  });
  const ibanRepeteeColumns = [
    generateColumns("Numéro de Contrat", "iban"),
    {
      title: "Nombre de répitition",
      dataIndex: "count",
      key: "count",
    },
  ];
  const paiementDepasse = Object.entries(payementLIst).map(([key, value]) => {
    return {
      key: key,
      iban: key,
      raison: value,
    };
  });
  const paiementDepasseColumns = [
    generateColumns("Transaction Id", "iban"),
    {
      title: "Raison de l'erreur",
      dataIndex: "raison",
      key: "raison",
    },
  ];
  const frequencePrelevement = frequencePayementList
    ? Object.entries(frequencePayementList).map(([key, value]) => {
        return {
          key: key,
          iban: key,
          raison: value,
        };
      })
    : [];
  const frequencePrelevementColumns = [
    generateColumns("Fichier Id", "iban"),
    {
      title: "Raison de l'erreur",
      dataIndex: "raison",
      key: "raison",
    },
  ];
  const montantIncoherent = montantInoherentList
    ? Object.entries(montantInoherentList).map(([key, value]) => {
        return {
          key: key,
          iban: key,
          raison: value,
        };
      })
    : [];
  const montantIncoherentColumns = [
    generateColumns("Fichier Id", "iban"),
    {
      title: "Raison de l'erreur",
      dataIndex: "raison",
      key: "raison",
    },
  ];
  const bankInfoChanges = bankInfoChangesList
    ? Object.entries(bankInfoChangesList).map(([key, value]) => {
        return {
          key: key,
          iban: key,
          raison: value,
        };
      })
    : [];
  const bankInfoChangesColumns = [
    generateColumns("IBAN", "iban"),
    {
      title: "Raison de l'erreur",
      dataIndex: "raison",
      key: "raison",
    },
  ];
  const datePrelevementChanges = datePrelevementList
    ? Object.entries(datePrelevementList).map(([key, value]) => {
        return {
          key: key,
          iban: key,
          raison: value,
        };
      })
    : [];
  const datePrelevementColumns = [
    generateColumns("IBAN", "iban"),
    {
      title: "Raison de l'erreur",
      dataIndex: "raison",
      key: "raison",
    },
  ];
  return (
    <Form layout="vertical" className={classes.container} form={form}>
      <Header />
      <Row className={classes.dropzone}>
        <Col className={classes.cols}>
          <div className={classes.dropzoneContent} {...getRootProps()}>
            <Input {...getInputProps()} />
            <div>
              <img src={upload} />
              <p className={classes.selectFile}>Glissez - Déposez ici</p>
            </div>

            <ConfigProvider>
              <Divider
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#CECECE",
                }}
              >
                ou
              </Divider>
            </ConfigProvider>
            <Button className={classes.buttonParcourir}>
              Parcourir les fichiers
            </Button>
            <aside>
              <ul className={classes.filename}>{files}</ul>
            </aside>
          </div>
        </Col>
        {/* <Col className={classes.cols}>
          <Form.Item
            name="montant"
            className={classes.montantInput}
            label={<label className={classes.label}>Montant plus que</label>}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col> */}
        <Col>
          <Row>
            <Button
              type="primary"
              style={{ backgroundColor: "#1b9038", margin: "1rem 1rem" }}
              onClick={getLists}
            >
              Vérifier
            </Button>
            <Button
              type="primary"
              style={{ backgroundColor: "#1b4090", margin: "1rem 1rem" }}
              onClick={() => navigate("/dashboard")}
            >
              Consulter les rapports
            </Button>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      {message !== "" && (
        <Row className={classes.errorrow}>
          <img src={existe ? warning : error ? errorIcon : tick} />
          <span
            style={{
              color: existe ? "#FF9F19" : !error ? "#2F8C2E" : "#EB0000",
              fontWeight: "600",
              fontSize: "15px",
            }}
          >
            {message}
          </span>
        </Row>
      )}

      {preview && (
        <div>
          {Object.keys(frequencePayementList).length ||
          Object.keys(datePrelevementList).length ||
          Object.keys(montantInoherentList).length ||
          Object.keys(bankInfoChangesList).length ||
          Object.keys(payementLIst).length ||
          Object.keys(ibanLIst).length ? (
            <Row>
              <Radio.Group
                value={activeGroup}
                onChange={handleTabChange}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {(Object.keys(frequencePayementList).length ||
                  Object.keys(datePrelevementList).length ||
                  Object.keys(montantInoherentList).length ||
                  Object.keys(bankInfoChangesList).length) && (
                  <Radio.Button
                    className={`${classes.radioButtons} ${
                      activeGroup === "avertissement"
                        ? classes.activeButton
                        : ""
                    }`}
                    style={{
                      backgroundColor: "#ec942c",
                      borderColor: "transparent",
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                    value="avertissement"
                  >
                    Avertissement
                  </Radio.Button>
                )}
                {(Object.keys(payementLIst).length ||
                  Object.keys(ibanLIst).length) && (
                  <Radio.Button
                    className={`${classes.radioButtons} ${
                      activeGroup === "erreur" ? classes.activeButton : ""
                    }`}
                    style={{
                      backgroundColor: "#f02c2c",
                      borderColor: "transparent",
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                    value={"erreur"}
                  >
                    Erreur
                  </Radio.Button>
                )}
              </Radio.Group>
            </Row>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span style={{ color: "#2F8C2E", fontWeight: "600" }}>
                  Aucune erreur ni avertissement trouvé.
                </span>
              }
            />
          )}

          {activeGroup === "avertissement" ? (
            <Row>
              <Tabs
                defaultActiveKey="WT001"
                activeKey={activeTabAvertissement}
                type="card"
                onChange={handleActiveTabAvertissement}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Object.keys(frequencePayementList).length && (
                  <TabPane tab="WT001" key="WT001" style={{ color: "000000" }}>
                    <Table
                      dataSource={frequencePrelevement}
                      columns={frequencePrelevementColumns}
                      style={{ width: "100%", marginBottom: "2rem" }}
                      bordered
                    />
                  </TabPane>
                )}
                {Object.keys(datePrelevementList).length && (
                  <TabPane tab="WT002" key="WT002" style={{ color: "000000" }}>
                    <Table
                      dataSource={datePrelevementChanges}
                      columns={datePrelevementColumns}
                      style={{ width: "100%", marginBottom: "2rem" }}
                      bordered
                    />
                  </TabPane>
                )}
                {Object.keys(montantInoherentList).length && (
                  <TabPane tab="WT003" key="WT003" style={{ color: "000000" }}>
                    <Table
                      dataSource={montantIncoherent}
                      columns={montantIncoherentColumns}
                      style={{ width: "100%", marginBottom: "2rem" }}
                      bordered
                    />
                  </TabPane>
                )}
                {Object.keys(bankInfoChangesList).length && (
                  <TabPane tab="WT004" key="WT004" style={{ color: "000000" }}>
                    <Table
                      dataSource={bankInfoChanges}
                      columns={bankInfoChangesColumns}
                      style={{ width: "100%", marginBottom: "2rem" }}
                      bordered
                    />
                  </TabPane>
                )}
              </Tabs>
            </Row>
          ) : (
            <Row>
              <Tabs
                defaultActiveKey="paiementDepasse"
                activeKey={activeTab}
                type="card"
                onChange={handleActiveTab}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Object.keys(payementLIst).length && (
                  <TabPane
                    tab="EB002"
                    key="paiementDepasse"
                    style={{ color: "000000" }}
                  >
                    <Table
                      dataSource={paiementDepasse}
                      columns={paiementDepasseColumns}
                      style={{ width: "100%", marginBottom: "2rem" }}
                      bordered
                    />
                  </TabPane>
                )}
                {Object.keys(ibanLIst).length && (
                  <TabPane
                    tab="EB003"
                    key="ibanRepetee"
                    style={{ color: "000000" }}
                  >
                    <Table
                      dataSource={ibanRepetee}
                      columns={ibanRepeteeColumns}
                      style={{ width: "100%", marginBottom: "2rem" }}
                      bordered
                    />
                  </TabPane>
                )}
              </Tabs>
            </Row>
          )}
        </div>
      )}
      <Footer />
    </Form>
  );
};

export default Home;
