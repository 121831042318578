// Signup.js

import React from "react";
import { Form, Input, Button, notification, Select } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./Signup.module.css";
import axios from "../utils/axios";
import logo from "../../Assets/logo.svg";

const { Option } = Select;

const Signup = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSignup = () => {
    const values = form.getFieldsValue();
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register`, values)
      .then((res) => {
        notification.success({
          message: "Success",
          description: "Votre compte a été créé avec succès.",
          duration: 2,
        });
        navigate("/");
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: err.response.data.message || "Une erreur s'est produite",
          duration: 2,
        });
      });
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Logo" className={styles.logo} />
        </div>
        <h2 className={styles.title}>Créer un compte</h2>
        <Form form={form} layout="vertical" onFinish={handleSignup}>
          <Form.Item
            name="username"
            label="Email :"
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre email",
              },
              {
                pattern: emailRegex,
                message: "Veuillez entrer une adresse email valide",
              },
            ]}
          >
            <Input
              className={styles.input}
              placeholder="email@email.com"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe :"
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre mot de passe",
              },
            ]}
          >
            <Input.Password
              className={styles.input}
              placeholder="Mot de passe"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role :"
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner un rôle",
              },
            ]}
          >
            <Select
              className={styles.input}
              placeholder="Sélectionnez un rôle"
              size="large"
            >
              <Option value="admin">Admin</Option>
              <Option value="user">Utilisateur</Option>
            </Select>
          </Form.Item>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.button}
              style={{ backgroundColor: "#1b4090" }}
              type="primary"
              htmlType="submit"
            >
              Créer un compte
            </Button>
          </div>
        </Form>
        {/* <div className={styles.loginLink}>
          Vous avez un compte? <Link to="/login">Se connecter</Link>
        </div> */}
      </div>
    </div>
  );
};

export default Signup;
