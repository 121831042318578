import React, { createContext } from "react";

const GlobalContext = createContext();

const Provider = ({ children }) => {
  const [preview, setPreview] = React.useState(false);
  const [ibanLIst, setIbanList] = React.useState([]);
  const [payementLIst, setPayementList] = React.useState([]);
  const [frequencePayementList, setFrequencePayementList] = React.useState([]);
  const [montantInoherentList, setMontantIncoherentList] = React.useState([]);
  const [bankInfoChangesList, setBankInfoChangesList] = React.useState([]);
  const [datePrelevementList, setDatePrelevementList] = React.useState([]);

  return (
    <GlobalContext.Provider
      value={{
        preview,
        setPreview,
        ibanLIst,
        setIbanList,
        payementLIst,
        setPayementList,
        frequencePayementList,
        setFrequencePayementList,
        montantInoherentList,
        setMontantIncoherentList,
        bankInfoChangesList,
        setBankInfoChangesList,
        datePrelevementList,
        setDatePrelevementList,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, Provider };
