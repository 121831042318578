import React from "react";
import classes from "./Header.module.css";
import logo from "../../../Assets/logo.svg";
import { Row } from "antd";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";

const Header = () => {
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");
  return (
    <Row className={classes.header}>
      <IoMdAddCircle
        className={classes.addIcon}
        onClick={() => {
          navigate("/");
        }}
      />
      <div className={classes.headerLogo}>
        <img src={logo} />
        <span className={classes.headerTitle}>Validateur SEPA</span>
      </div>
      <div className={classes.headerButton}>
        {role === "admin" && (
          <button
            className={classes.createAccountButton}
            onClick={() => {
              navigate("/signup");
            }}
          >
            Créer un compte
          </button>
        )}
        <FaSignOutAlt
          className={classes.logoutIcon}
          onClick={() => {
            sessionStorage.clear();
            navigate("/login");
          }}
        />
      </div>
    </Row>
  );
};

export default Header;
