import React, { useEffect, useState } from "react";
import Header from "../../components/Layouts/Header/Header";
import {
  Button,
  Table,
  Modal,
  ConfigProvider,
  Divider,
  Input,
  Row,
  Col,
  notification,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import classes from "./Dashboard.module.css";
import axios from "../../components/utils/axios";
import CustomSearch from "../../components/CustomSearch";
import {
  SearchOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../context/GlobalContext";
import Footer from "../../components/Layouts/Header/Footer";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/upload.svg";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const {
    setPreview,
    setIbanList,
    setPayementList,
    setFrequencePayementList,
    setMontantIncoherentList,
    setBankInfoChangesList,
    setDatePrelevementList,
  } = React.useContext(GlobalContext);
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [modalVisible, setModalVisible] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [modalReloadVisible, setModalReloadVisible] = useState(false);
  const [recordToReload, setRecordToReload] = useState(null);
  const navigate = useNavigate();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});

  const generateColumns = (title, dataIndex, sorter) => ({
    title: title,
    dataIndex: dataIndex,
    key: dataIndex,
    sorter: sorter,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <CustomSearch
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const getReports = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get_reports`, {
        headers: {
          "Access-Token": sessionStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        setData(response.data.reports);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getReports();
  }, []);

  const handleReload = (record) => {
    setRecordToReload(record);
    setModalReloadVisible(true);
  };

  const handleRemove = (record) => {
    setRecordToDelete(record);
    setModalVisible(true);
  };
  const confirmReplacement = () => {
    setLoading(true);
    if (acceptedFiles.length > 0) {
      let formData = new FormData();
      formData.append(`selectedFileName`, acceptedFiles[0]);
      formData.append(`existed_file`, recordToReload.file);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/validate`, formData, {
          headers: {
            "Access-Token": sessionStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          setModalReloadVisible(false);
          if (response.data.error === false) {
            notification.success({
              message: "Succès",
              description: response.data.message,
            });
            getReports();
          } else {
            notification.error({
              message: "Erreur",
              description: response.data.message,
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setModalReloadVisible(false);
          notification.error({
            message: "Erreur",
            description: error.data.message,
          });
          setLoading(false);
        });
    } else {
      notification.error({
        message: "Erreur",
        description: "Veuillez ajouter le fichier à analyser",
      });
    }
  };
  const confirmDeletion = () => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/delete`,
        {
          fileName: recordToDelete.file,
        },
        {
          headers: {
            "Access-Token": sessionStorage.getItem("access_token"),
          },
        }
      )
      .then((response) => {
        setData(response.data.reports);
        setModalVisible(false);
        getReports();
      })
      .catch((error) => {
        console.error("Error deleting report:", error);
        setModalVisible(false);
        notification.error({
          message: "Erreur",
          description: error.data.message,
        });
      });
  };

  const reloadButtonStyle = {
    color: "#1b9038",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const removeButtonStyle = {
    color: "red",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const reloadColumnStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const removeColumnStyle = {
    display: "flex",
    justifyContent: "center",
  };
  const rapportColumns = [
    generateColumns("Nom de fichier", "file"),
    generateColumns("Creancier Id", "creancierId"),
    generateColumns("Date de création", "creation_date", (a, b) =>
      a.creation_date.localeCompare(b.creation_date)
    ),
    {
      title: "Live Preview",
      dataIndex: "livePreview",
      render: (livepreview) => (
        <a
          onClick={() => {
            setPreview(true);
            setIbanList(livepreview?.repeated_transactions || {});
            setPayementList(livepreview?.invalid_formats || {});
            setFrequencePayementList(livepreview?.frequence_prelevement || {});
            setMontantIncoherentList(livepreview?.montant_incoherent || {});
            setBankInfoChangesList(livepreview?.bank_info_changes || {});
            setDatePrelevementList(livepreview?.date_prelevementt_check || {});
            navigate("/");
          }}
          className={classes.livePreviewLink}
        >
          Live Preview
        </a>
      ),
    },
    {
      title: "Rapport",
      dataIndex: "report",
      render: (rapport) => (
        <a
          href={`data:application/pdf;base64,${rapport}`}
          download="report.pdf"
          className={classes.reportLink}
        >
          Download Report
        </a>
      ),
    },
    {
      title: "Analyser à nouveau",
      dataIndex: "reload",
      render: (text, record) => (
        <div style={reloadColumnStyle}>
          <Button
            type="link"
            icon={<ReloadOutlined />}
            style={reloadButtonStyle}
            onClick={() => handleReload(record)}
          />
        </div>
      ),
    },
    {
      title: "Supprimer",
      dataIndex: "remove",
      render: (text, record) => (
        <div style={removeColumnStyle}>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            style={removeButtonStyle}
            onClick={() => handleRemove(record)}
          />
        </div>
      ),
      ...removeColumnStyle,
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const rapports = data?.map((rapport) => ({
    key: rapport.file_name,
    file: rapport.file_name,
    creancierId: rapport.creancier_id,
    creation_date: rapport.creation_date,
    report: rapport.report,
    livePreview: rapport.report_details,
  }));
  const files = acceptedFiles.map((file) => (
    <li style={{ listStyle: "none", marginTop: "1rem" }} key={file.path}>
      {file.path}
    </li>
  ));
  return (
    <div>
      <Modal
        title="Analyser à nouveau"
        visible={modalReloadVisible}
        onOk={confirmReplacement}
        onCancel={() => setModalReloadVisible(false)}
        okText="Confirmer"
        cancelText="Annuler"
        centered
        okButtonProps={{
          style: { background: "#1b9038", borderColor: "#1b9038" },
        }}
      >
        <Row className={classes.dropzone}>
          <Col className={classes.cols}>
            <div className={classes.dropzoneContent} {...getRootProps()}>
              <Input {...getInputProps()} />
              <div>
                <img src={upload} />
                <p className={classes.selectFile}>Glissez - Déposez ici</p>
              </div>

              <ConfigProvider>
                <Divider
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#CECECE",
                  }}
                >
                  ou
                </Divider>
              </ConfigProvider>
              <Button className={classes.buttonParcourir}>
                Parcourir les fichiers
              </Button>
              <aside>
                <ul className={classes.filename}>{files}</ul>
              </aside>
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Confirmation"
        visible={modalVisible}
        onOk={confirmDeletion}
        onCancel={() => setModalVisible(false)}
        okText="Confirmer"
        cancelText="Annuler"
        centered
        okButtonProps={{ style: { background: "red", borderColor: "red" } }}
      >
        Êtes-vous sûr de vouloir supprimer le fichier des prélèvement et son
        rapport rapport ?
      </Modal>

      <Header />
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "25px",
          fontWeight: "700",
        }}
      >
        Les rapport générées
      </h2>
      <div className={classes.container}>
        {loading ? (
          <Spin size="large" style={{ color: "#1b4090" }} />
        ) : (
          <div className={classes.tableContainer}>
            <Table
              dataSource={rapports}
              columns={rapportColumns}
              className={classes.customTable}
              bordered
              pagination={pagination}
              onChange={handleTableChange}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
