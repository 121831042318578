import React from "react";
import { Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const CustomSearch = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder="Search..."
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => confirm()}
      style={{ width: 188, marginBottom: 8, display: "block" }}
    />
    <Button
      type="primary"
      onClick={() => confirm()}
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      <SearchOutlined />
      Search
    </Button>
    <Button
      onClick={() => clearFilters()}
      size="small"
      style={{ width: 90 }}
    >
      Reset
    </Button>
  </div>
);

export default CustomSearch;
