import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Signup from "./components/SignUp/SignUp";
import Login from "./components/Login/Login";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Provider } from "./context/GlobalContext";
const App = () => {
  return (
    <Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={
          <PrivateRoute role="admin">
          <Signup />
          </PrivateRoute>
          } />
          <Route path="/login" element={
          <Login />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
